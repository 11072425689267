import React from 'react';

export default class OrganizationOfficeDetails extends React.Component {
    render() {
        return(
            <div>
                Şube Detayları
            </div>
        )
    }
}
