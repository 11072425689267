import React from 'react';
import {setCookie} from "../utils";

export default class WixLogin extends React.Component {
    constructor(props) {
        super(props);
        const token = this.props.match.params.user_token;
        if (token) {
            setCookie("lvtauth", token);
            this.props.history.push("/organizations");
        }
    }
    render() {
        return (
            <div>
                Uygualamaya Yönelendirilyorsunuz...
            </div>
        );
    }
}
