import React from 'react';
import {Row, Col, Input, Button} from 'antd';
import {loginAction} from "../reduxUtils/actions";
import {connect} from 'react-redux';
class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
        }
        this.handleInput = this.handleInput.bind(this);
        this.loginClick = this.loginClick.bind(this);
    }
    handleInput(e) {
        this.setState( {[e.target.name]: e.target.value});
    }
    loginClick(){
        const {email, password} = this.state;
        const data = {email, password};
        this.props.loginAction({
            data,
            endpoint: "auth/login",
            events: {
                onSuccess : (resp) => this.props.history.push("/organizations")
            }
        });
    }
    render() {
        return(
            <Row gutter={[8, 8]} style={{height: '100%'}}  type="flex" justify="center" align="middle">
                <Col xs={22} sm={22} md={16} lg={8}>
                    <Row gutter={[32, 32]}>
                        <Col span={24}>
                            <Input onChange={this.handleInput} name="email" size="large" placeholder="E-mail" />
                        </Col>
                        <Col span={24}>
                            <Input onChange={this.handleInput} name="password" size="large" placeholder="Şifre" type="password" />
                        </Col>
                        <Col span={24}>
                            <Button onClick={this.loginClick} type="primary" size="large" style={{width: '100%'}}>Giriş Yap</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
export default connect(null, {loginAction} )(Login);
