import React from 'react';
import { get, getOrganizationInformation, getOrganizationOfficeInformation } from '../../reduxUtils/actions';
import { connect } from 'react-redux';
import {Button, List, PageHeader} from "antd";
import { OfficeSelectionModal } from '../Shared';
class BoardsList extends React.Component {
    constructor(props) {
        super(props);
        this.editClick = this.editClick.bind(this);
        this.designerClick = this.designerClick.bind(this);
        this.openOfficeSelectionModal = this.openOfficeSelectionModal.bind(this);
        this.loadFrameBoards = this.loadFrameBoards.bind(this);
        this.generatePageTitle = this.generatePageTitle.bind(this);
        this.officesModalRef = React.createRef();
    }

    componentDidMount() {
        this.loadFrameBoards();
    }

    loadFrameBoards() {
        const officeId = this.props.match.params.office_id;
        this.props.get({endpoint: `organization-offices/${officeId}/frame-boards`}, `${officeId}-frameBoards`)
    }

    editClick(boardId) {
        const officeId = this.props.match.params.office_id;
        this.props.history.push(`/boards/${officeId}/edit/${boardId}`);
    }

    designerClick(boardId) {
        const officeId = this.props.match.params.office_id;
        window.location.href = `/boards/${officeId}/designer/${boardId}`;
    }

    openOfficeSelectionModal(board) {
        if (this.officesModalRef && this.officesModalRef.current) {
            this.officesModalRef.current.openModal(board);
        }
    }

    generatePageTitle() {
        return [
            "Mağaza Listesi",
            this.props.organization ? this.props.organization.name : null,
            "Şube Listesi",
            this.props.office ? this.props.office.name : null,
            "Hareket Kanvasları"
        ].filter((i1)=>!!i1).join('/')
    }

    render() {
        const officeId = this.props.match.params.office_id;
        return(
            <div style={{ padding: '0px 20%'}}>
                <PageHeader
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    ghost={false}
                    title={this.generatePageTitle()}
                    onBack={() => this.props.history.goBack()}
                    extra={[
                        <Button key="1" type="dashed" onClick={() => this.props.history.push(`/boards/${officeId}/new`)}>
                            Yeni Kanvas
                        </Button>,
                    ]}
                />
                <List
                    className="demo-loadmore-list"
                    loading={!this.props.frameBoards}
                    itemLayout="horizontal"
                    loadMore={false}
                    size="large"
                    dataSource={this.props.frameBoards}
                    renderItem={item => (
                        <List.Item
                            className={this.props.office.active_board_id == item.id ? 'active-board' : null}
                            actions={[
                                <a key="list-loadmore-edit" onClick={ (e) => this.editClick(item.id)}>Bigileri Düzenle</a>,
                                <a key="list-loadmore-more" onClick={ (e) => this.designerClick(item.id) }>Hareket Düzenle</a>,
                                <a key="list-loadmore-more" onClick={ (e) => this.openOfficeSelectionModal(item) }>Şubelere Uygula</a>]}
                        >
                            <div style={{ width: '100%', fontSize: 16 }}>{item.board_name}</div>
                        </List.Item>
                    )}
                />
                <OfficeSelectionModal ref={this.officesModalRef} onSave={this.loadFrameBoards} />
            </div>
        )
    }
}

export default connect((state,ownProps) => {
    const officeId = ownProps.match.params.office_id;
    return {
        frameBoards : state && state.generic && state.generic[`${officeId}-frameBoards`]&& state.generic[`${officeId}-frameBoards`].frameBoards,
        organization : state && state.generic && state.generic[`${officeId}-frameBoards`]&& state.generic[`${officeId}-frameBoards`].organization,
        office : state && state.generic && state.generic[`${officeId}-frameBoards`]&& state.generic[`${officeId}-frameBoards`].office,
        organizationsWithOffices : state && state.generic && state.generic["organizations-with-offices"],
    }
},{ get, getOrganizationInformation, getOrganizationOfficeInformation })(BoardsList)
