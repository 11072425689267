import React from 'react';
import {Button, Col, Input, PageHeader, Row} from "antd";
import {connect} from "react-redux";
import {post, put, getOrganizationInformation, clearOrganizationInformation} from "../../reduxUtils/actions";

class OrganizationForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleInput = this.handleInput.bind(this);
        this.createOrganization = this.createOrganization.bind(this);
        this.editOrganization = this.editOrganization.bind(this);
        this.getFormMode = this.getFormMode.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.generatePageTitle = this.generatePageTitle.bind(this);
        this.state = {
            organization_name: ''
        };
        if (this.props.match.params.id) {
            this.organizationID = this.props.match.params.id;
            this.props.getOrganizationInformation(this.organizationID);
        }
    }

    static getDerivedStateFromProps(props, currentState) {
        if (currentState.organization_name === '' && props.organizationDetails.name) {
            return {
                organization_name: props.organizationDetails.name,
                organization_name_initial: props.organizationDetails.name,
            }
        }
        return null
    }

    handleInput(e) {
        this.setState({
            organization_name: e.target.value,
        });
    }

    createOrganization(event) {
        const postData = {
            organization_name: this.state.organization_name,
        }
        this.props.post(postData, {
            endpoint: 'organizations',
            events: {
                onSuccess: () => this.props.history.goBack()
            }
        })
    }

    editOrganization(event) {
        const putData = {
            organization_name: this.state.organization_name,
        }
        this.props.put(putData, {
            endpoint: 'organizations/' + this.organizationID,
            events: {
                onSuccess: () => this.props.history.goBack(),
                onFail: () => this.props.history.goBack()
            }
        })
    }

    handleCreateClick(e) {
        if (this.getFormMode() === "EDIT") {
            return this.editOrganization(e);
        }

        return this.createOrganization(e);
    }

    getFormMode() {
        if (this.props.history
            && this.props.history.location
            && this.props.history.location.pathname
            && this.props.history.location.pathname.indexOf('/edit/') >= 0
        ) {
            return "EDIT";
        }
        return "NEW";
    }

    generatePageTitle() {
        const pathArray = [
            "Mağazalar",
            (this.state.organization_name_initial || null),
            this.getFormMode() === "EDIT" ? "Mağazayı Düzenle" : "Yeni Mağaza Oluştur"
        ];
        return pathArray.filter((i1)=>!!i1).join('/')
    }

    componentWillUnmount() {
        this.props.clearOrganizationInformation();
    }

    render() {
        return (
            <div style={{padding: '0px 20%'}}>
                <PageHeader
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    ghost={false}
                    onBack={() => this.props.history.goBack()}
                    title={this.generatePageTitle()}
                />
                <Row gutter={[8, 8]} style={{height: '100%'}} type="flex" justify="center" align="middle">
                    <Col xs={22} sm={22} md={16} lg={8}>
                        <Row gutter={[32, 32]}>
                            <Col span={24}>
                                <Input onChange={this.handleInput} name="email" size="large"
                                       value={this.state.organization_name}
                                       placeholder="Mağaza Adı"/>
                            </Col>
                            <Col span={24}>
                                <Button onClick={this.handleCreateClick} type="primary" size="large"
                                        style={{width: '100%'}}>{
                                    this.getFormMode() === "EDIT" ? "Düzenle" : "Oluştur"
                                }</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default connect((state, ownProps) => {
    return ({
        organizationDetails: state && state.generic && state.generic.selectedOrganization || {}
    })
}, {post, put, getOrganizationInformation, clearOrganizationInformation})(OrganizationForm);
