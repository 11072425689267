import React from 'react';
import {GepettoCanvas, GepettoCommander, GepettoCanvasErrors} from '../components/GepettoDesigner'
import {Col, Row} from "antd";
import {connect} from 'react-redux';
import { initializeDesigner } from '../reduxUtils/actions';

const canvasColStyle = {padding: '32px 8px 32px 32px', maxHeight: 'calc(100vh - 108px)'};
const errorColStyle = {padding: '32px 8px 13px 32px', height: '100%'}

class GepettoMain extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { match: { params }, initializeDesigner } = this.props;
        initializeDesigner({ boardId: params.id });
    }

    validateCanvas(designer) {
        document.body.classList.add('stop-scrolling');
        try {
            if (designer.showcaseDimensions.width <= 0 || designer.showcaseDimensions.height <= 0) {
                return { value: 1, text: "Lütfen vitrin ölçülerini girin"};
            }
            if (designer.motors.first.position == null) {
                return { value: 100, text: "Lütfen 1. Makaranın pozisyonunu girin"};
            }
            if (designer.motors.second.position == null) {
                return { value: 100, text: "Lütfen 2. Makaranın pozisyonunu girin"};
            }
            if (designer.motors.third.position == null) {
                return { value: 100, text: "Lütfen 3. Makaranın pozisyonunu girin"};
            }
            if (designer.motors.fourth.position == null) {
                return { value: 100, text: "Lütfen 4. Makaranın pozisyonunu girin"};
            }
        } catch (e) {

        }
        document.body.classList.remove('stop-scrolling');
        return null
    }


    render() {
        const { match: { params }, designer } = this.props;
        let error = this.validateCanvas(designer);
        return (
            <Row gutter={[0, 0]} style={{height: '100%'}}>
                <Col md={18} lg={18} style={error ? errorColStyle : canvasColStyle}>
                    <GepettoCanvasErrors error={error}/>
                    <GepettoCanvas errorOccured={!!error}/>
                </Col>
                <Col md={6} lg={6} style={{padding: '32px 32px 13px 8px', height: '100%'}}>
                    <GepettoCommander goBack={this.props.history.goBack} error={error} params={params}/>
                </Col>
            </Row>
        )
    }
}

export default connect((state) => ({
    designer: state && state.designer || {}
}), { initializeDesigner })(GepettoMain);
