import Api from "../api";
import {setCookie} from "../../utils";

export const designerAcitonTypes = {
    ADD_MOTOR: 'ADD_MOTOR',
    REMOVE_MOTOR: 'REMOVE_MOTOR',
    CHANGE_MOTOR_POSITION: 'CHANGE_MOTOR_POSITION',
    CHANGE_SHOWCASE_DIMENSIONS: 'CHANGE_SHOWCASE_DIMENSIONS',
    CHANGE_MOTOR_COUNT: 'CHANGE_MOTOR_COUNT',
    CREATE_NEW_FRAME: 'CREATE_NEW_FRAME',
    SET_MOTOR_POSITON_IN_FRAME: 'SET_MOTOR_POSITON_IN_FRAME',
    SET_MOTOR_PAIRS: 'SET_MOTOR_PAIRS',
    INITIALIZE_DESIGNER: 'INITIALIZE_DESIGNER',
    SET_ACTIVE_FRAME: 'SET_ACTIVE_FRAME',
    DELETE_FRAME: 'DELETE_FRAME',
    REPLACE_FRAME: 'REPLACE_FRAME',
    COPY_FRAME: 'COPY_FRAME',
    CHANGE_FRAME_DURATION: 'CHANGE_FRAME_DURATION'
};


export function addMotor(position) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.ADD_MOTOR,
            data: {
                position
            }
        });
    }
}

export function removeMotor(index) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.REMOVE_MOTOR,
            data: {
                index
            }
        });
    }
}

export function changeMotorPosition(motorName, position) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.CHANGE_MOTOR_POSITION,
            data: {
                motorName,
                position,
            }
        })
    }
}

export function changeShowcaseDimensions(data) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.CHANGE_SHOWCASE_DIMENSIONS,
            data
        })
    }
}

export function changeMotorCount(motorCount) {
    return function (dispatch) {
        if (isNaN(parseInt(motorCount)) || motorCount > 4 || motorCount < 0) {
            motorCount = 4
        }
        dispatch({
            type: designerAcitonTypes.CHANGE_MOTOR_COUNT,
            data: { motorCount }
        })
    }
}

export function createNewFrame(id, duration, coords) {
    return function (dispatch) {
        const newData = {
            id,
            duration
        };
        if (coords) {
            newData.coords = coords;
        }
        dispatch({
            type: designerAcitonTypes.CREATE_NEW_FRAME,
            data: newData
        })
    }
}

export function setMotorPositionInFrame(frameID, coords, motorName) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.SET_MOTOR_POSITON_IN_FRAME,
            data: {
                frameID,
                coords,
                motorName
            }
        })
    }
}

export function setMotorPairs(pairConnectionStr) {
    return function (dispatch, getStore) {
        let { designer } = getStore();
        Object.keys(designer.motors).forEach( (m1) => {
            delete designer.motors[m1].pair;
        });
        if (pairConnectionStr) {
            let pairsList = pairConnectionStr.split(',');
            pairsList.forEach((pair) => {
                let [ p1, p2 ] = pair.split(':');
                designer.motors[p1].pair = p2;
            });
        }
        dispatch({
            type: designerAcitonTypes.SET_MOTOR_PAIRS,
            data: {
                motors: designer.motors,
                pairString: pairConnectionStr
            },
        })
    }
}

export function saveDesigner({ events, boardId }){
    return async function(dispatch, getState) {
        const { designer: { activeFrame,initialized, ...designerRest } } = getState();
        try {
            const resp = await Api.putData(designerRest, `frame-boards/${boardId}`);
            if (resp.success) {
                if (events && typeof events.onSuccess === 'function') {
                    events.onSuccess(resp);
                }
            } else {
                if (events && typeof events.onFail === 'function') {
                    events.onFail(resp);
                }
            }
        } catch (e) {
            console.log("saveDesigner action error",e)
        }
        return null;
    }
}

export function initializeDesigner({events, boardId}){
    return async function(dispatch, getState) {
        try {
            const resp = await Api.getData(`frame-boards/${boardId}`);
            dispatch({
                type: designerAcitonTypes.INITIALIZE_DESIGNER,
                data: resp.data,
            })
            if (resp.success) {
                if (events && typeof events.onSuccess === 'function') {
                    events.onSuccess(resp);
                }
            } else {
                if (events && typeof events.onFail === 'function') {
                    events.onFail(resp);
                }
            }
        } catch (e) {
            console.log("initializeDesigner action error", e)
        }
        return null;
    }
}

export function setActiveFrame(frameID) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.SET_ACTIVE_FRAME,
            data: {
                frameID
            }
        })
    }
}

export function deleteFrame(frameID) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.DELETE_FRAME,
            data: {
                frameID
            }
        })
    }
}

export function replaceFrame(frameID, newFrame) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.REPLACE_FRAME,
            data: {
                frameID,
                newFrame
            }
        })
    }
}

export function copyFrame(frameData) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.COPY_FRAME,
            data: {
                frameData
            }
        })
    }
}

export function changeFrameDuration(frameID, newDuration) {
    return function (dispatch) {
        dispatch({
            type: designerAcitonTypes.CHANGE_FRAME_DURATION,
            data: {
                frameID,
                newDuration
            }
        })
    }
}
