import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import * as Pages from './pages';
import {setCookie} from "./utils";

function Routes() {
    return (
        <Router>
            <Route exact path="/" component={Pages.Home}/>
            <Route exact path="/organizations" component={Pages.OrganizationList}/>
            <Route exact path="/organizations/new" component={Pages.OrganizationForm}/>
            <Route exact path="/organizations/view/:id" component={Pages.OrganizationDetails}/>
            <Route exact path="/organizations/edit/:id" component={Pages.OrganizationForm}/>

            <Route exact path="/offices/:organization_id" component={Pages.OrganizationOfficesList}/>
            <Route exact path="/offices/:organization_id/new" component={Pages.OrganizationOfficesForm}/>
            <Route exact path="/offices/:organization_id/view/:id" component={Pages.OrganizationOfficeDetails}/>
            <Route exact path="/offices/:organization_id/edit/:id" component={Pages.OrganizationOfficesForm}/>

            <Route exact path="/boards/:office_id" component={Pages.BoardsList}/>
            <Route exact path="/boards/:office_id/new" component={Pages.BoardsForm}/>
            <Route exact path="/boards/:office_id/view/:id" component={Pages.BoardDetails}/>
            <Route exact path="/boards/:office_id/edit/:id" component={Pages.BoardsForm}/>
            <Route exact path="/boards/:office_id/designer/:id" component={Pages.GepettoMain}/>

            <Route exact path="/wix-login/:user_token" component={Pages.WixLogin}/>
            <Route exact path="/login" component={Pages.Login}/>
        </Router>
    )
}

export default Routes;
