import React from 'react';
import {Button, Col, Input, PageHeader, Row} from "antd";
import {connect} from "react-redux";
import {post, put, getFrameBoardInformation, getOrganizationOfficeInformation, getOrganizationInformation, clearFrameBoardInformation} from "../../reduxUtils/actions";

class BoardsForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleInput = this.handleInput.bind(this);
        this.createFrameBoard = this.createFrameBoard.bind(this);

        this.editFrameBoard = this.editFrameBoard.bind(this);
        this.getFormMode = this.getFormMode.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.state = {
            board_name: ''
        };
        if (this.props.match.params.id) {
            this.frameBoardID = this.props.match.params.id;
            this.props.getFrameBoardInformation(this.frameBoardID);
        }
    }

    static getDerivedStateFromProps(props, currentState) {
        if (currentState.board_name === '' && props.boardDetails.board_name) {
            return {
                board_name: props.boardDetails.board_name,
            }
        }
        return null
    }

    handleInput(e) {
        this.setState({
            board_name: e.target.value,
        });
    }

    createFrameBoard(event) {
        const postData = {
            board_name: this.state.board_name,
            organization_office_id: this.props.match.params.office_id
        };
        this.props.post(postData, {
            endpoint: 'frame-boards',
            events: {
                onSuccess: () => this.props.history.goBack()
            }
        })
    }

    editFrameBoard(event) {
        const putData = {
            board_name: this.state.board_name,
        }
        this.props.put(putData, {
            endpoint: 'frame-boards/' + this.frameBoardID,
            events: {
                onSuccess: () => this.props.history.goBack(),
                onFail: () => this.props.history.goBack()
            }
        })
    }

    handleCreateClick(e) {
        if (this.getFormMode() === "EDIT") {
            return this.editFrameBoard(e);
        }

        return this.createFrameBoard(e);
    }

    getFormMode() {
        if (this.props.history
            && this.props.history.location
            && this.props.history.location.pathname
            && this.props.history.location.pathname.indexOf('/edit/') >= 0
        ) {
            return "EDIT";
        }
        return "NEW";
    }

    componentWillUnmount() {
        this.props.clearFrameBoardInformation();
    }

    render() {
        return (
            <div style={{padding: '0px 20%'}}>
                <PageHeader
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    ghost={false}
                    onBack={() => this.props.history.goBack()}
                    title={this.getFormMode() === "EDIT" ? "Kanvas Düzenleme: " + (this.props.boardDetails.board_name || '') : "Yeni Kanvas"}
                />
                <Row gutter={[8, 8]} style={{height: '100%'}} type="flex" justify="center" align="middle">
                    <Col xs={22} sm={22} md={16} lg={8}>
                        <Row gutter={[32, 32]}>
                            <Col span={24}>
                                <Input onChange={this.handleInput} size="large"
                                       value={this.state.board_name}
                                       placeholder="Kanvas Adı"/>
                            </Col>
                            <Col span={24}>
                                <Button onClick={this.handleCreateClick} type="primary" size="large"
                                        style={{width: '100%'}}>{
                                    this.getFormMode() === "EDIT" ? "Düzenle" : "Oluştur"
                                }</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default connect((state, ownProps) => {
    return ({
        boardDetails: state && state.generic && state.generic.selectedFrameBoard || {}
    })
}, {post, put, getFrameBoardInformation, getOrganizationOfficeInformation, getOrganizationInformation, clearFrameBoardInformation})(BoardsForm);
