import * as genericActions from './genericActions';

const storeName = 'selectedOrganization';

export function getOrganizationInformation(organization_id) {
    return async function (dispatch) {
        clearOrganizationInformation()(dispatch);
        await genericActions.get({
            endpoint: "organizations/" + organization_id,
        },storeName)(dispatch);
    }
}

export function clearOrganizationInformation() {
    return async function (dispatch) {
        genericActions.clearStoreByName(storeName)(dispatch);
    }
}
