import React from 'react';
import { get } from '../../reduxUtils/actions';
import { connect } from 'react-redux';
import {Button, List, PageHeader} from "antd";

class OrganizationOfficesList extends React.Component {
    constructor(props) {
        super(props);
        this.editClick = this.editClick.bind(this);
        this.generatePageTitle = this.generatePageTitle.bind(this);
        this.frameBoardsClick = this.frameBoardsClick.bind(this);
    }
    componentDidMount() {
        const orgId = this.props.match.params.organization_id;
        this.props.get(({
            endpoint: `organizations/${orgId}/organization-offices`
        }),`${orgId}-offices`);
    }

    editClick(orgId, officeId) {
        this.props.history.push(`/offices/${orgId}/edit/${officeId}`);
    }

    frameBoardsClick(officeId) {
        this.props.history.push(`/boards/${officeId}`);
    }

    generatePageTitle() {
        const pathArray = [
            "Mağazalar",
            this.props.organization.name,
            "Şubeler"
        ];
        return pathArray.filter((i1)=>!!i1).join('/')
    }

    render() {
        const orgId = this.props.match.params.organization_id;

        return(
            <div style={{ padding: '0px 20%'}}>
                <PageHeader
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    ghost={false}
                    onBack={() => this.props.history.goBack()}
                    title={this.generatePageTitle()}
                    extra={[
                        <Button key="1" type="dashed" onClick={() => this.props.history.push(`/offices/${orgId}/new`)}>
                            Yeni Şube
                        </Button>,
                    ]}
                />
                <List
                    className="demo-loadmore-list"
                    loading={!this.props.offices}
                    itemLayout="horizontal"
                    loadMore={false}
                    size="large"
                    dataSource={this.props.offices}
                    renderItem={item => (
                        <List.Item
                            actions={[<a key="list-loadmore-edit" onClick={ (e) => this.editClick(orgId, item.id)}>Düzenle</a>, <a key="list-loadmore-more" onClick={ (e) => this.frameBoardsClick(item.id) }>G'petto Kanvasları</a>]}
                        >
                            <div style={{ width: '100%', fontSize: 16 }}>{item.name}</div>
                        </List.Item>
                    )}
                />
            </div>
        )
    }
}

export default connect((state,ownProps) => {
    const orgId = ownProps.match.params.organization_id;
    if (state && state.generic && state.generic[`${orgId}-offices`]) {
        return {
            offices: state.generic[`${orgId}-offices`].offices,
            organization: state.generic[`${orgId}-offices`].organization
        };
    }
    return {
        offices: [],
        organization: {},
    }
}, { get })(OrganizationOfficesList);
