import React from 'react';
import { connect } from 'react-redux';

import {
    drawStroke,
    loadImage,
    createRefVariable,
    setDimensions,
    drawCircle,
} from './canvasHelpers';
import CursorPositionLabel from './CursorPositionLabel';
import GepettoIcon from '../../assets/img/gepetto_icon_small.png';
import {deepEqual} from "../../utils";
import {setMotorPositionInFrame} from "../../reduxUtils/actions";
const framePointColors = {
    active: '#389E0D',
    previous: '#BFBFBF'
};
const getActiveAndPreviousFrames  = (frameList, activeFrame) => {
    let result = {
        active: null,
        previousFrames: [],
    };
    frameList.forEach( (frame) => {
        if (frame.id == activeFrame ) {
            result.active = frame;
        } else {
            result.previousFrames.push(frame)
        }
    });
    return result;
}
class GepettoCanvas extends React.Component {

    constructor(props) {
        super(props);
        const { showcaseDimensions } = this.props;
        this.state = {
            showcaseDimensions: showcaseDimensions,
            canvasMultiplier: 1,
            canvasDimensions: {
                width: showcaseDimensions.width,
                height: showcaseDimensions.height,
            },
            currentMotor: 0,
        };

        this.clearCursorContext = this.clearCursorContext.bind(this);
        this.initializeCoordinatePlane = this.initializeCoordinatePlane.bind(this);
        this.drawCrosshair = this.drawCrosshair.bind(this);
        this.setContainerHeights = this.setContainerHeights.bind(this);
        this.resetCursorCanvas = this.resetCursorCanvas.bind(this);
        this.mouseCanvasClickHandler = this.mouseCanvasClickHandler.bind(this);
        this.clearBackgroundContext = this.clearBackgroundContext.bind(this);
        this.dimensionChangeHandler = this.dimensionChangeHandler.bind(this);
        this.canvasKeyboardHandler = this.canvasKeyboardHandler.bind(this);
        this.markFramePoints = this.markFramePoints.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const { showcaseDimensions: oldDimensions } = this.state;
        const { showcaseDimensions: newDimensions } = nextProps;
        if (!deepEqual(oldDimensions, newDimensions)) {

        }
        this.dimensionChangeHandler(nextProps);
        // this.initializeCoordinatePlane();
    }

    componentDidMount() {
        this.dimensionChangeHandler(this.props);
        document.body.onkeypress = this.canvasKeyboardHandler;
    }

    componentWillUnmount() {
        document.body.onkeypress = null;
    }

    dimensionChangeHandler(nextProps) {
        const { width: showcaseWidth, height: showcaseHeight } = nextProps.showcaseDimensions;
        if (showcaseWidth <= 0 || showcaseHeight <= 0) {
            return this.state;
        }
        const containetWidth = this.parentDiv.clientWidth;
        let calculateNewState = (prevState) => {
            let newState = {};
            if (containetWidth > showcaseWidth) {
                const newMultiplier = containetWidth / showcaseWidth;
                newState.canvasMultiplier = newMultiplier;
                newState.canvasDimensions = {
                    width: showcaseWidth * newMultiplier,
                    height: showcaseHeight * newMultiplier,
                }
            } else {
                newState.canvasMultiplier = 1;
                newState.canvasDimensions = {
                    width: showcaseWidth,
                    height: showcaseHeight,
                }
            }
            return newState
        };
        this.setState(calculateNewState, () => {
            const { canvasDimensions } = this.state;
            setDimensions(this.backgroundCanvas, canvasDimensions);
            setDimensions(this.cursorCanvas, canvasDimensions);
            this.initializeCoordinatePlane();
            this.setContainerHeights();
            this.markFramePoints(nextProps);
        })
    }

    initializeCoordinatePlane() {
        const { canvasDimensions: { width, height }, canvasMultiplier } = this.state;
        const { motors } = this.props;
        this.clearBackgroundContext();
        const drawStyle = {lineWidth: 5, strokeStyle: '#DDD'};
        const draw = drawStroke(this.backgroundContext, drawStyle);
        const putImage = loadImage(this.backgroundContext);
        this.backgroundContext.textAlign = "center";
        /*this.backgroundContext.fillStyle = '#efefef';
        this.backgroundContext.fillRect(0, 0, width, height);*/
        draw([0, height], [width, height]);
        Object.keys(motors).forEach((k1) => {
            if (!motors[k1].disabled){
                let canvasX = motors[k1].position * canvasMultiplier;
                putImage(GepettoIcon, [canvasX, 0], {
                    style: {
                        width: 16,
                        height: 16
                    }
                });
                draw([canvasX, height], [canvasX, height - 10]);
            }
        });

    }

    markFramePoints(nextProps) {
        const { frames, activeFrame } = nextProps;
        const { active, previousFrames } =  getActiveAndPreviousFrames(frames, activeFrame);
        const circleDrawer = drawCircle(this.backgroundContext, this.state.canvasMultiplier);
        previousFrames.forEach((i1) => {
            if (i1 && i1.coords) {
                Object.keys(i1.coords).forEach((motor) => {
                    circleDrawer(i1.coords[motor], framePointColors.previous);
                })
            }
        });
        if (active && active.coords) {
            Object.keys(active.coords).forEach((motor) => {
                circleDrawer(active.coords[motor], framePointColors.active);
            })
        }
    }

    clearCursorContext() {
        const {width, height} = this.state.canvasDimensions;
        this.cursorContext.clearRect(0, 0, width, height);
    }

    clearBackgroundContext() {
        const {width, height} = this.state.canvasDimensions;
        this.backgroundContext.clearRect(0, 0, width, height);
    }

    resetCursorCanvas() {
        const {width, height} = this.state.canvasDimensions;
        this.cursorLabel.changeLabelPosition({ width:-1, height: -1 });
        this.cursorContext.clearRect(0, 0, width, height);
    }

    drawCrosshair(e) {
        const { canvasDimensions: {width, height}, canvasMultiplier, currentMotor } = this.state;
        const { motors, motorOrders, activeFrame, motorCount } = this.props;
        if (activeFrame === null) {
            return;
        }
        e.persist();
        const rect = this.cursorCanvas.getBoundingClientRect();
        let mousePosVer = e.clientX - rect.left + 1;
        let currentMotorName = motorOrders[currentMotor % motorCount];
        let motor = motors[currentMotorName];
        let firstX = ( motor.position ) * canvasMultiplier;
        let secondX = firstX;
        if (motor.pair && motors[motor.pair]) {
            secondX = (motors[motor.pair].position * canvasMultiplier);
        }
        if (mousePosVer > (secondX)) {
            mousePosVer = (secondX);
        }
        if (mousePosVer < (firstX)) {
            mousePosVer = (firstX);
        }
        let mousePosHor = e.clientY - rect.top + 1;
        this.clearCursorContext();
        this.cursorContext.setLineDash([7, 7]);
        const draw = drawStroke(this.cursorContext, {strokeStyle: '#777'});
        draw([firstX, 0], [mousePosVer, mousePosHor]);
        if (firstX == secondX) {
            draw([0, mousePosHor], [width, mousePosHor]);
        } else {
            draw([secondX, 0], [mousePosVer, mousePosHor]);
        }
        this.cursorLabel.changeLabelPosition({width: mousePosVer, height: mousePosHor}, {
            left: e.screenX,
            top: e.screenY - 108
        })
    }

    setContainerHeights() {
        this.containerDiv.style.maxWidth = this.parentDiv.style.width;
        this.containerDiv.style.maxHeight = this.parentDiv.style.height;
    }

    isPair(motors, motorName, order){
        let res = -1;
        Object.keys(motors).forEach((i1) => {
            if(motors[i1].pair == motorName) {
                res = Math.abs(order.indexOf(motorName) - order.indexOf(i1));
            }
        });
        return res;
    }

    mouseCanvasClickHandler(e) {
        const { currentMotor } = this.state;
        const { motors, activeFrame, motorOrders, setMotorPositionInFrame, motorCount } = this.props;
        if (activeFrame === null) {
            return;
        }
        const labelPosition = this.cursorLabel.getCurrentPosition();
        setMotorPositionInFrame(activeFrame, { x: parseFloat(labelPosition.x), y: parseFloat(labelPosition.y) }, motorOrders[currentMotor % motorCount]);
        e.persist();

        let nextMotor = currentMotor + 1;
        const nextMotorName = motorOrders[nextMotor % motorCount];
        if (this.isPair(motors, nextMotorName, motorOrders) > -1) {
            nextMotor = nextMotor + this.isPair(motors, nextMotorName, motorOrders);
        }
        this.setState({
            currentMotor: nextMotor,
        }, () => {
            this.drawCrosshair(e);
        });
    }

    canvasKeyboardHandler(e) {
        // n: 110, p: 112
        // N for next motor, P for previous motor
        const { currentMotor } = this.state;
        if (e.keyCode === 110) {
            this.setState({ currentMotor: currentMotor + 1})
        }
        if (e.keyCode === 112) {
            let prevMotor = (currentMotor - 1);
            if (prevMotor < 0) {
                prevMotor = 0;
            }
            this.setState({currentMotor: prevMotor})
        }
    }

    render() {
        const {width, height} = this.state.canvasDimensions;
        const { errorOccured } = this.props;
        let style = { maxHeight : 'inherit'}
        if (errorOccured) {
            style = {...style, ...{visibility: 'hidden'}}
        }
        return (
            <div ref={createRefVariable.call(this, 'parentDiv')} style={style} className="no-text-selection">
                <div className="max-height_inherit" ref={createRefVariable.call(this, 'containerDiv')} style={{ display: 'flex', flex: 1, minHeight: 0, overflow: 'auto'}}>
                    <canvas
                        onMouseOut={this.resetCursorCanvas}
                        ref={createRefVariable.call(this, 'cursor', true)}
                        style={{width, height, zIndex: 1, cursor: 'none'}}
                        onClick={this.mouseCanvasClickHandler}
                        onMouseMove={this.drawCrosshair}/>
                    <canvas
                        ref={createRefVariable.call(this, 'background', true)}
                        style={{width, height, zIndex: 0, marginLeft: width * -1, border: '2px solid #EDEDED'}}/>
                    <CursorPositionLabel ref={createRefVariable.call(this, "cursorLabel")}
                                         canvasMultiplier={this.state.canvasMultiplier}/>
                </div>
            </div>
        );
    }
}

export default connect((state) => {
    let result = {
        motors: state && state.designer && state.designer.motors ? {...state.designer.motors} : {},
        showcaseDimensions: state && state.designer && state.designer.showcaseDimensions ? {...state.designer.showcaseDimensions} : {},
        motorOrders: (state && state.designer && state.designer.motorOrders) || [],
        frames: state && state.designer && state.designer.frames || [],
        activeFrame: state && state.designer && state.designer.activeFrame || null,
        motorCount: state && state.designer && state.designer.motorCount || 4
    };
    if (result.motors && Object.keys(result.motors).length > 0) {
        Object.keys(result.motors).forEach((k1) => {
            if (result.motors[k1].disabled) {
                delete result.motors[k1];
            }
        });
    }
    return result;
}, { setMotorPositionInFrame })(GepettoCanvas);
