import React from 'react';
import {Col, Row} from "antd";


export default class GepettoCanvasErrors extends React.Component {
    render() {
        const { error }  = this.props;
        if (!error || !error.text) {
            return null;
        }
        return(
            <div style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '28px',
                border: '2px solid #EDEDED',
                backgroundColor: '#DDD',
            }}>
                {error.text}
            </div>
        )
    }
}
