import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import thunk from 'redux-thunk';
import {authReducer, designerReducer, genericReducer} from './reducers';

const allReducers = combineReducers({
    auth: authReducer,
    designer: designerReducer,
    generic: genericReducer
})
const composeEnhancers =
    typeof window === 'object' &&
    process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;
const enhancer = composeEnhancers(
    applyMiddleware(thunk),
);
const store = createStore(allReducers, enhancer)
export default store;
