import {authAcitonTypes} from '../actions';

export function authReducer(state = {}, action) {
    switch(action.type) {
        case authAcitonTypes.LOGIN_SUCCESS:
            return {...state,...action.data};
            break;
        default:
            return state;
    }
}
