import React from "react";

class CursorPositionLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canvasDimensions: {
                width: 0,
                height: 0,
            },
            labelPosition: {
                left: 0,
                top: 0
            },
            coords: {
                x: 0,
                y: 0,
            }
        }
        this.changeLabelPosition = this.changeLabelPosition.bind(this);
        this.getCurrentPosition = this.getCurrentPosition.bind(this);
    }

    changeLabelPosition(canvasDimensions, labelPosition) {
        const {canvasMultiplier} = this.props;
        const coords = {
            x: ((canvasDimensions.width) / canvasMultiplier).toFixed(2),
            y: ((canvasDimensions.height) / canvasMultiplier).toFixed(2)
        };
        this.setState({canvasDimensions, labelPosition, coords});
    }

    getCurrentPosition() {
        const {canvasDimensions} = this.state;
        const {canvasMultiplier} = this.props;
        const coordsX = (canvasDimensions.width) / canvasMultiplier;
        const coordsY = (canvasDimensions.height) / canvasMultiplier;
        return {x: coordsX.toFixed(2), y: coordsY.toFixed(2)};
    }

    render() {
        const {coords, labelPosition} = this.state;

        if (coords.x < 0 && coords.y < 0) {
            return null;
        }
        return (
            <span style={{position: 'fixed', left: labelPosition.left, top: labelPosition.top, zIndex: 0, margin: -16}}>
        {`${coords.x}, ${coords.y}`}
      </span>
        );
    }
}

export default CursorPositionLabel
