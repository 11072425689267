import {genericActionTypes} from '../actions';

export function genericReducer(state = {}, action) {
    switch(action.type) {
        case genericActionTypes.GET_DATA_TO_STORE:
            return {...state,[action.storeName] : action.data};
            break;
        case genericActionTypes.CLEAR_STORE_BY_NAME:
            let tempState = {...state};
            delete tempState[action.storeName];
            return {...tempState};
            break;
        default:
            return state;
    }
}
