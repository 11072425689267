import Api from '../api';

export const genericActionTypes = {
    GET_DATA_TO_STORE : 'GET_DATA_TO_STORE',
    GENERIC_POST: 'GENERIC_POST',
    GENERIC_PUT: 'GENERIC_PUT',
    CLEAR_STORE_BY_NAME: 'CLEAR_STORE_BY_NAME'
};

export function get(options, storeName){
    return async function (dispatch) {
        const {endpoint, events} = options
        try {
            const resp = await Api.getData(endpoint);
            if (resp.success) {
                dispatch({
                    type: genericActionTypes.GET_DATA_TO_STORE,
                    data: resp.data,
                    storeName: storeName
                });
                if (events && typeof events.onSuccess === 'function') {
                    events.onSuccess(resp);
                }
            } else {
                if (events && typeof events.onFail === 'function') {
                    events.onFail(resp);
                }
            }
            return resp;
        } catch (e) {
            console.log("Generic get error: ",e)
        }
    }
}

export function post(data, options){
    return async function (dispatch) {
        const {endpoint, events} = options
        try {
            const resp = await Api.sendData(data, endpoint);
            if (resp.success) {
                dispatch({
                    type: genericActionTypes.GENERIC_POST,
                    data: resp.data,
                });
                if (events && typeof events.onSuccess === 'function') {
                    events.onSuccess(resp);
                }
            } else {
                if (events && typeof events.onFail === 'function') {
                    events.onFail(resp);
                }
            }
        } catch (e) {
            console.log("Generic post error: ",e)
        }
    }
}

export function put(data, options){
    return async function (dispatch) {
        const {endpoint, events} = options
        try {
            const resp = await Api.putData(data, endpoint);
            if (resp.success) {
                dispatch({
                    type: genericActionTypes.GENERIC_PUT,
                    data: resp.data,
                });
                if (events && typeof events.onSuccess === 'function') {
                    events.onSuccess(resp);
                }
            } else {
                if (events && typeof events.onFail === 'function') {
                    events.onFail(resp);
                }
            }
        } catch (e) {
            console.log("Generic put error: ",e)
        }
    }
}

export function clearStoreByName(storeName) {
    return function (dispatch) {
        dispatch({
            type: genericActionTypes.CLEAR_STORE_BY_NAME,
            storeName: storeName
        });
    }
}
