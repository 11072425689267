import React from 'react';
import { get } from '../../reduxUtils/actions'
import { connect } from 'react-redux';
import {Avatar, Button, List, PageHeader, Skeleton} from "antd";
class OrganizationsList extends React.Component {
    constructor(props) {
        super(props);
        this.editClick = this.editClick.bind(this);
        this.officesClick = this.officesClick.bind(this);
    }
    componentDidMount() {
        this.props.get({
            endpoint: 'organizations',
        }, 'orglist');
    }

    editClick(orgId) {
        this.props.history.push(`/organizations/edit/${orgId}`);
    }

    officesClick(orgId) {
        this.props.history.push(`/offices/${orgId}`);
    }

    render() {
        return(
            <div style={{ padding: '0px 20%'}}>
                <PageHeader
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    ghost={false}
                    title="Mağazalar"
                    extra={[
                        <Button key="1" type="dashed" onClick={() => this.props.history.push(`/organizations/new`)}>
                            Yeni Mağaza
                        </Button>,
                    ]}
                />
                <List
                    className="demo-loadmore-list"
                    loading={!this.props.organizations}
                    itemLayout="horizontal"
                    loadMore={false}
                    size="large"
                    dataSource={this.props.organizations}
                    renderItem={item => (
                        <List.Item
                            actions={[<a key="list-loadmore-edit" onClick={ (e) => this.editClick(item.id)}>Düzenle</a>, <a key="list-loadmore-more" onClick={ (e) => this.officesClick(item.id) }>Şubeler</a>]}
                        >
                            <div style={{ width: '100%', fontSize: 16 }}>{item.name}</div>
                        </List.Item>
                    )}
                />
            </div>
        )
    }
}

export default connect((state) => ({
    organizations: state && state.generic && state.generic.orglist,
}), { get })(OrganizationsList)
