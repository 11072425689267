import * as genericActions from './genericActions';

const storeName = 'selectedFrameBoard';

export function getFrameBoardInformation(frame_board_id) {
    return async function (dispatch) {
        // clearFrameBoardInformation()(dispatch);
        await genericActions.get({
            endpoint: "frame-boards/" + frame_board_id,
        },storeName)(dispatch);
    }
}

export function clearFrameBoardInformation() {
    return async function (dispatch) {
        genericActions.clearStoreByName(storeName)(dispatch);
    }
}
