import Api from '../api';
import {setCookie} from "../../utils";

export const authAcitonTypes = {
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
}


export function loginAction(options) {
    return async function (dispatch) {
        const {data, endpoint, events} = options
        try {
            const resp = await Api.sendData(data,endpoint);
            if (resp.success) {
                dispatch({
                    type: authAcitonTypes.LOGIN_SUCCESS,
                    data: resp.data
                });
                setCookie("lvtauth", resp.data.token)
                if (events && typeof events.onSuccess === 'function') {
                    events.onSuccess(resp);
                }
            } else {
                if (events && typeof events.onFail === 'function') {
                    events.onFail(resp);
                }
            }
        } catch (e) {
            console.log("Login action error",e)
        }
    }
}

