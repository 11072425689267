import axios from 'axios';
import {cookieByName} from "../utils";

// const APIURL = "http://levitate-api.reactivers.com";
//const APIURL = "http://165.22.84.134:8000";
// const APIURL = "http://localhost:8000";
// const APIURL = "http://192.168.1.34:8000";
const APIURL = "https://app-api.levitate.com.tr";

// const APIURL = "https://test.reactivers.com";

axios.defaults.withCredentials = true;
class Api {
    static getGenericConfig() {
        const config = {
            withCredentials: true
        }
        const cookie = cookieByName('lvtauth');
        if (cookie) {
            config.headers = {
                'Authorization': cookie
            }
        }
        return config
    }
    static async sendData(data, apiEndPoint) {
        try {
            const response = await axios.post(APIURL + "/" + apiEndPoint, data, this.getGenericConfig());
            return {...response.data}
        } catch (error) {
            return {err: error}
        }
    }

    static async putData(data, apiEndPoint) {
        try {
            const response = await axios.put(APIURL + "/" + apiEndPoint, data, this.getGenericConfig());
            return {...response.data}
        } catch (error) {
            return {err: error}
        }
    }

    static async getData(apiEndPoint) {
        try {
            const response = await axios.get(APIURL + "/" + apiEndPoint, this.getGenericConfig());
            return {...response.data}
        } catch (error) {
            return {err: error}
        }
    }

    static async deleteData(apiEndPoint) {
        try {
            const response = await axios.delete(APIURL + "/" + apiEndPoint, this.getGenericConfig());
            return {...response.data}
        } catch (error) {
            return {err: error}
        }
    }

    static async getDataFromOutsider(url) {
        try {
            const response = await axios.get(url);
            return {...response.data}
        } catch (error) {
            return {err: error}
        }
    }

}

export default Api
