import React from 'react';
import {Button, Col, Input, PageHeader, Row} from "antd";
import {connect} from "react-redux";
import {post, put, getOrganizationOfficeInformation, clearOrganizationOfficeInformation, getOrganizationInformation} from "../../reduxUtils/actions";

class OrganizationOfficesForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleInput = this.handleInput.bind(this);
        this.createOffice = this.createOffice.bind(this);
        this.editOffice = this.editOffice.bind(this);
        this.getFormMode = this.getFormMode.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.generatePageTitle = this.generatePageTitle.bind(this);
        this.state = {
            office_name: ''
        };
        this.props.getOrganizationInformation(this.props.match.params.organization_id);
        if (this.props.match.params.id) {
            this.props.getOrganizationOfficeInformation(this.props.match.params.id);
            this.organizationOfficeID = this.props.match.params.id;
        }
    }

    static getDerivedStateFromProps(props, currentState) {
        if (currentState.office_name === '' && props.officeDetails.name) {
            return {
                office_name: props.officeDetails.name,
                office_name_initial: props.officeDetails.name,
            }
        }
        return null
    }

    handleInput(e) {
        this.setState({
            office_name: e.target.value,
        });
    }

    createOffice(event) {
        const postData = {
            office_name: this.state.office_name,
            organization_id: this.props.match.params.organization_id
        }
        this.props.post(postData, {
            endpoint: 'organization-offices',
            events: {
                onSuccess: () => this.props.history.goBack()
            }
        })
    }

    editOffice(event) {
        const putData = {
            office_name: this.state.office_name,
        }
        this.props.put(putData, {
            endpoint: 'organization-offices/' + this.organizationOfficeID,
            events: {
                onSuccess: () => this.props.history.goBack(),
                onFail: () => this.props.history.goBack()
            }
        })
    }

    handleCreateClick(e) {
        if (this.getFormMode() === "EDIT") {
            return this.editOffice(e);
        }

        return this.createOffice(e);
    }

    getFormMode() {
        if (this.props.history
            && this.props.history.location
            && this.props.history.location.pathname
            && this.props.history.location.pathname.indexOf('/edit/') >= 0
        ) {
            return "EDIT";
        }
        return "NEW";
    }

    componentWillUnmount() {
        this.props.clearOrganizationOfficeInformation();
    }

    generatePageTitle() {
        const pathArray = [
            "Mağazalar",
            (this.props.organizationDetails.name || null),
            (this.state.office_name_initial || null),
            this.getFormMode() === "EDIT" ? "Şubeyi Düzenle" : "Yeni Şube Oluştur"
        ];
        return pathArray.filter((i1)=>!!i1).join('/')
    }


    render() {
        return (
            <div style={{padding: '0px 20%'}}>
                <PageHeader
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    ghost={false}
                    onBack={() => this.props.history.goBack()}
                    title={this.generatePageTitle()}
                />
                <Row gutter={[8, 8]} style={{height: '100%'}} type="flex" justify="center" align="middle">
                    <Col xs={22} sm={22} md={16} lg={8}>
                        <Row gutter={[32, 32]}>
                            <Col span={24}>
                                <Input onChange={this.handleInput} size="large"
                                       value={this.state.office_name}
                                       placeholder="Şube Adı"/>
                            </Col>
                            <Col span={24}>
                                <Button onClick={this.handleCreateClick} type="primary" size="large"
                                        style={{width: '100%'}}>{
                                    this.getFormMode() === "EDIT" ? "Düzenle" : "Oluştur"
                                }</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default connect((state, ownProps) => {
    return ({
        officeDetails: state && state.generic && state.generic.selectedOrganizationOffice || {},
        organizationDetails: state && state.generic && state.generic.selectedOrganization || {}
    })
}, {post, put, getOrganizationOfficeInformation, clearOrganizationOfficeInformation, getOrganizationInformation})(OrganizationOfficesForm);
