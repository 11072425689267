import * as genericActions from './genericActions';

const storeName = 'selectedOrganizationOffice';

export function getOrganizationOfficeInformation(organization_office_id) {
    return async function (dispatch) {
        clearOrganizationOfficeInformation()(dispatch);
        await genericActions.get({
            endpoint: "organization-offices/" + organization_office_id,
        },storeName)(dispatch);
    }
}

export function clearOrganizationOfficeInformation() {
    return async function (dispatch) {
        genericActions.clearStoreByName(storeName)(dispatch);
    }
}
