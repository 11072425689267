import React from 'react';
export default class OrganizationDetails extends React.Component {
    render() {
        return(
            <div>
                Mağaza Detayları
            </div>
        )
    }
}
