import React from 'react';
import {get, post} from '../../reduxUtils/actions';
import {connect} from 'react-redux';
import {Modal, TreeSelect} from 'antd';
const InitialState = {
    opened: false,
    boardData: null,
    isLoading: false,
    value: null
};
class OfficeSelectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = InitialState
        this.openModal = this.openModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.sendPostRequest = this.sendPostRequest.bind(this);
        this.props.get({endpoint: `organizations/get-all-with-offices`}, `organizations_with_offices`);
    }
    showLoading(callback) {
        this.setState({isLoading: true}, () => callback());
    }
    sendPostRequest() {
        const idList = {};
        this.props.organizationsWithOffices.forEach((i1) => {
            idList[i1.value] = i1.children.map((i2) => i2.value)
        });
        let officeIDs = [];
        this.state.value.forEach((i1) => {
            if (idList[i1]) {
                officeIDs = [...officeIDs, ...idList[i1]];
            } else {
                officeIDs = [...officeIDs, i1];
            }
        });
        this.props.post({
            office_ids: officeIDs,
            board_id: this.state.boardData.id,
            deleted_from: this.state.deleted,
        }, {
            endpoint: 'organization-offices/set-active-board',
            events: {
                onSuccess: () => {
                    this.props.onSave();
                    this.setState(InitialState)
                }
            }
        })
    }
    handleSave(){
        this.showLoading(this.sendPostRequest);
    }

    handleCancel() {
        this.setState({
            opened: false,
            value: null,
        })
    }

    handleChange(inputValue) {
        const deleted = this.state.value.filter((i1)=>inputValue.indexOf(i1) < 0);
        this.setState({
            deleted,
            value: inputValue
        })
    }

    openModal(boardData) {
        this.setState({
            opened: true,
            boardData,
            value: boardData.used_by_offices.map((i1) => i1.id)
        })
    }

    render() {
        if (!this.props.organizationsWithOffices || !this.state.boardData) {
            return null;
        }
        return (
            <Modal
                title={`"${this.state.boardData.board_name}" Kanvası Şube Seçimi`}
                cancelText="İptal"
                okText="Kaydet"
                visible={this.state.opened}
                onOk={this.handleSave}
                confirmLoading={this.state.isLoading}
                onCancel={this.handleCancel}
            >
                <TreeSelect
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    placeholder="Şube Seçiniz"
                    value={this.state.value}
                    onChange={this.handleChange}
                    style={{width: '100%'}}
                    treeData={this.props.organizationsWithOffices}
                />
            </Modal>
        )
    }
}

export default connect((state) => {
    function apiDataToTreeSelect(data) {
        return data && data.length > 0 ? data.map((i1) => {
            return {
                title: i1.organization_name,
                value: i1.organization_id,
                children: i1.offices.map((i1) => ({title: i1.office_name, value: i1.office_id, key: i1.office_id}))
            }
        }) : null;
    }

    return {
        organizationsWithOffices: state && state.generic && apiDataToTreeSelect(state.generic.organizations_with_offices),
    }
}, {get, post}, null, {forwardRef: true})(OfficeSelectionModal);
