import React from 'react';
import ReactDOM from 'react-dom';
import store from './reduxUtils';
import {Provider} from 'react-redux';
import Routes from './Routes';
import './assets/css/main.css';
import 'antd/dist/antd.less';

import Logo from './assets/img/logo.png';
import {Layout, Menu} from "antd";
import './prototypes';
// F4A41C
function AppContainer(){
    return(
        <Provider store={store}>
            <Layout className="layout">
                <Layout.Header style={{ background : '#000' }}>
                    <img alt="" src={Logo} className="header-logo" />
                    <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{ lineHeight: '64px' }}>
                    </Menu>
                </Layout.Header>
                <Layout.Content style={{backgroundColor : 'white', height: '100%'}}>
                    <Routes />
                </Layout.Content>
            </Layout>
        </Provider>
    )
}

ReactDOM.render(<AppContainer />, document.getElementById('root'));

