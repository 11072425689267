import React from 'react';
import {Form} from "antd";
import CommanderInput from './CommanderInput';
export default class ShowcaseConfigurationForm extends React.Component {
    render() {
        return(
            <Form layout="vertical">
                <Form.Item label="Genişlik" style={{width: '100%'}}>
                    <CommanderInput mainStore="showcaseDimensions" storePath="width" style={{ width: '100%' }} addonAfter="cm"/>
                </Form.Item>
                <Form.Item label="Yükseklik" style={{width: '100%'}}>
                    <CommanderInput mainStore="showcaseDimensions" storePath="height" style={{ width: '100%' }} addonAfter="cm"/>
                </Form.Item>
                <Form.Item label="Makara Sayısı" style={{width: '100%'}}>
                    <CommanderInput mainStore="motorCount" style={{ width: '100%' }}/>
                </Form.Item>
            </Form>
        )
    }
}
