import React from 'react';

export default class BoardDetails extends React.Component {
    render() {
        return(
            <div>
                Kanvas Detayları
            </div>
        )
    }
}
