import React from 'react';
import { connect } from 'react-redux';
import {Row, Card, Tabs, Button, Icon, notification } from "antd"
import MotorConfigurationForm from "./MotorConfigurationForm";
import ShowcaseConfigurationForm from "./ShowcaseConfigurationForm";
import FrameViewer from "./FrameViewer";
import { saveDesigner } from "../../../reduxUtils/actions";
import styled from 'styled-components';
// import { HomeOutlined } from '@ant-design/icons';
const GreyButton = styled.div`
    .ant-btn-primary {
        background-color:#8c8c8c;
        border-color:#8c8c8c;
    }
    .ant-btn-primary:hover {
        background-color:#bfbfbf;
        border-color:#bfbfbf;
    }
    .ant-btn-primary:active {
        background-color:#434343;
        border-color:#434343;
    }
`;

class GepettoCommander extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'showcase'
        };
        this.saveButtonClickHandler = this.saveButtonClickHandler.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.changeTabTo = this.changeTabTo.bind(this);
    }

    changeTabTo(tabName) {
        return () => {
            this.setState({ activeTab: tabName })
        }
    }

    async saveButtonClickHandler(e) {
        const { params } = this.props;
        if (this.state.activeTab != 'frames') {
            await this.setState({ activeTab: 'frames' });
        }
        document.getElementById('DEGISTIR').disabled = true;
        setTimeout( () => document.getElementById('DEGISTIR').disabled = false, 1500)
        this.props.saveDesigner({
            boardId: params.id,
            events: {
                onSuccess: (resp) => {
                    notification.open({
                        message: 'Successful!',
                        description: resp.message,
                        onClick: () => {
                        },
                    });
                },
                onFail: (resp) => {
                    notification.open({
                        message: 'Request Failed:',
                        description:  resp.message,
                        onClick: () => {
                        },
                    });
                }
            }
        })
    }

    onTabClick(tabKey) {
        this.setState({ activeTab: tabKey });
    }

    render() {
        const { activeTab } = this.state;
        const { error } = this.props;
        return (
            <Row gutter={[0, 0]} style={{height: '100%'}}>
                <Card style={{ width: '100%', height: '100%'}} bodyStyle={{ padding: '0px', height: 'calc(100vh - 168px)'}} actions={[
                    <GreyButton>
                        <Button type="primary" onClick={() => this.props.goBack()}>
                            Şubeye Dön
                        </Button>
                    </GreyButton>,
                    activeTab === 'showcase' && <Button type="primary" disabled={(error && error.value) === 1} onClick={this.changeTabTo('motors')} >
                        İleri
                        <Icon type="right" />
                    </Button>,
                    activeTab !== 'showcase' && <Button type="primary" onClick={this.changeTabTo(activeTab === 'frames' ? 'motors' : 'showcase')} icon="left">Geri</Button>,
                    activeTab !== 'showcase' && <Button id="DEGISTIR" type="primary" disabled={false && !!error} onClick={this.saveButtonClickHandler} icon="save">Kaydet</Button>
                ].filter((i1) => !!i1)}>
                    <Tabs activeKey={activeTab} tabPosition="top" style={{ height: '100%'}} onTabClick={this.onTabClick}>
                        <Tabs.TabPane tab={`Vitrin`} key="showcase" style={{ padding: '16px 16px 0px 16px', height:'calc(100vh - 200px)', overflow:'scroll' }}>
                            <ShowcaseConfigurationForm/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`Makaralar`} key="motors" style={{ padding: '16px 16px 0px 16px', height:'calc(100vh - 200px)', overflow:'scroll' }}>
                            <MotorConfigurationForm/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`Katmanlar`} key="frames" style={{ marginTop: '-16px' }}>
                            <FrameViewer office_id={this.props.params.office_id}/>
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </Row>
        )
    }
}

export default connect(null, { saveDesigner })(GepettoCommander);
