export const drawStroke = function(context, styles) {

    return function(startPoint,endPoint) {

        Object.keys(styles).forEach( (i1) => {
            context[i1] = styles[i1];
        });

        context.beginPath();
        context.moveTo(...startPoint);
        context.lineTo(...endPoint);
        context.stroke();
        context.closePath();
    }
};

export const loadImage = function(context) {
    let imageStore = {};
    return function(imageURL, coordinates, imageAttributes) {
        let tempImage = imageStore[imageURL];
        if (tempImage) {
            context.drawImage(imageStore[imageURL], ...coordinates);
        } else {
            tempImage = new Image();
            tempImage.src = imageURL;
            tempImage.style.marginLeft= '-8px';
            tempImage.onload = () => {
                // Store loaded images in an object.
                imageStore[imageURL] = tempImage;
                context.drawImage(tempImage, ...coordinates);
            }
        }
    }
};

export const createRefVariable = function(refName, isCanvas) {
    if (isCanvas) {
        if (!this[`${refName}Canvas`] && !this[`${refName}Context`]) {
            return (ref) => {
                if (ref) {
                    this[`${refName}Canvas`] = ref;
                    this[`${refName}Context`] = ref.getContext('2d');
                }
            }
        }
    } else {
        if (!this[refName]) {
            return (ref) => {
                if (ref) {
                    this[refName] = ref;
                }
            }
        }
    }
};

export const setDimensions = function(canvasInstance, dimensions) {
    const {width, height} = dimensions;
    canvasInstance.width = width;
    canvasInstance.height = height;
};

export const drawCircle = function (context, multiplier) {
    return function (points, color) {
        context.beginPath();
        context.strokeStyle = color;
        context.fillStyle = color;
        context.arc(points.x * multiplier, points.y * multiplier, 3, 0, 2 * Math.PI);
        context.stroke();
        context.fill();
        context.closePath();
    }
};
