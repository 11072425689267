import React from 'react';

import {
    List,
    Icon,
    Input,
    Popconfirm,
    Popover,
    Table,
    InputNumber,
    Button,
    Tooltip
} from 'antd';

import {connect} from "react-redux";
import {
    createNewFrame,
    deleteFrame,
    setActiveFrame,
    replaceFrame,
    copyFrame,
    changeFrameDuration
} from "../../../reduxUtils/actions";
import styled from 'styled-components';

import ReactDraggable from 'react-draggable-list';

const Item = List.Item;
const Meta = Item.Meta;

const generateFrameID = function (items) {
    let nextID = 1;
    items.forEach((i1) => {
        if (i1.id >= nextID) {
            nextID = parseInt(i1.id) + 1;
        }
    });
    return nextID;
};

const motorObjKeysToName = {
    "first": "1.",
    "second": "2.",
    "third": "3.",
    "fourth": "4."
};

const IconButton = styled.div`
    .ant-btn-primary {
        color: #434343;
        background-color: #f5f5f5;
        border-color: #f5f5f5;
    }
    .ant-btn-primary:hover {
        color: #434343;
        background-color: #fafafa;
        border-color: #fafafa;
    }
    .ant-btn-primary:active {
        color: #434343;
        background-color: #d9d9d9;
        border-color: #d9d9d9;
    }
`;

const CommandLink = styled.span`
    a {
        color: #141414;
        margin-right: 4px
    }
    a:hover {
        color: #434343;
    }
`;

class FrameViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newDuration: null,
            linkPopVisible: false
        };
        this.newFrameClickHandler = this.newFrameClickHandler.bind(this);
        this.renderListItem = this.renderListItem.bind(this);
        this.deleteButtonClickhandler = this.deleteButtonClickhandler.bind(this);
        this.selectButtonClickHandler = this.selectButtonClickHandler.bind(this);
        this.renderMotorTextInputForm = this.renderMotorTextInputForm.bind(this);
        this.copyFrameClickHandler = this.copyFrameClickHandler.bind(this);
        this.frameDurationChangeHandler = this.frameDurationChangeHandler.bind(this);
        this.hideLinkPop = this.hideLinkPop.bind(this);
        this.handleLinkPopVisibleChange = this.handleLinkPopVisibleChange.bind(this);
        this.generateCommanderLink = this.generateCommanderLink.bind(this);
        this.getFrameByID = this.getFrameByID.bind(this);
        this.moveButtonClickHandler = this.moveButtonClickHandler.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.frames && this.props.frames.length === 0) {
            // createNewFrame(generateFrameID([]), 1);
        }
    }

    deleteButtonClickhandler(frameID) {
        this.props.deleteFrame(frameID);
    }

    selectButtonClickHandler(frameID) {
        this.props.setActiveFrame(frameID)
    }

    generateFrameTitle(frameID) {
        if (frameID === 1) {
            return "İlk Katman"
        }
        return `${frameID}. Katman`
    }

    updateMotorInFrame(axis) {
        const self = this;
        return function (frame, motors, newValue) {
            let newFrame = {...frame};
            motors.forEach((motor) => {
                if (newFrame && newFrame.coords && newFrame.coords[motor]) {
                    newFrame.coords[motor][axis] = newValue;
                }
            });
            self.props.replaceFrame(newFrame.id, newFrame);
        }
    }

    getFrameByID(frameID) {
        const currentFrame = this.props.frames.filter((frame) => frame.id == frameID)[0];
        return currentFrame;
    }

    createMotorPairs(motors, motorCount) {
        let result = [];
        const usedInForm = (motorIterator) => JSON.stringify(result).indexOf(motorIterator) >= 0;
        ['first', 'second', 'third', 'fourth'].slice(0, motorCount).forEach((motorIterator) => {
            // Don't add input for pairs
            if (usedInForm(motorIterator)) {
                return;
            }
            let motorArr = [motorIterator];
            if (typeof motors[motorIterator].pair === "string") {
                motorArr.push(motors[motorIterator].pair);
            }
            result.push(motorArr);
        });
        return result;
    }

    renderMotorTextInputForm(frame) {
        if (!this.props.motors) {
            return null;
        }
        const motorPairs = this.createMotorPairs(this.props.motors, this.props.motorCount);
        let dataSource = [];
        const currentFrame = this.getFrameByID(frame.id);
        motorPairs.forEach((motorRows) => {
            let motorNames = motorRows.map((motorObjKey) => motorObjKeysToName[motorObjKey]);
            let pairedMotors = motorNames.length > 1;
            let defaultX = this.props.motors[motorRows[0]].x;
            let defaultY = null;
            if (currentFrame && currentFrame.coords && currentFrame.coords[motorRows[0]]) {
                defaultX = currentFrame.coords[motorRows[0]].x;
                defaultY = currentFrame.coords[motorRows[0]].y;
            }
            dataSource.push({
                motorTitle: pairedMotors ? motorNames.join(" ve ") + " Makaralar" : motorNames + " Makara",
                coordX: <InputNumber defaultValue={defaultX} step={0.01}
                                     disabled={!pairedMotors}
                                     onChange={(newX) => this.updateMotorInFrame('x')(frame, motorRows, newX)}/>,
                coordY: <InputNumber defaultValue={defaultY} step={0.01}
                                     onChange={(newY) => this.updateMotorInFrame('y')(frame, motorRows, newY)}/>,
                key: motorNames.join(""),
            })
        });

        const columns = [
            {
                title: '',
                dataIndex: 'motorTitle',
                key: 'motorTitle',
            },
            {
                title: 'X',
                dataIndex: 'coordX',
                key: 'coordX',
            },
            {
                title: 'Y',
                dataIndex: 'coordY',
                key: 'coordY',
            },
        ];
        return (
            <Table
                size="small"
                pagination={false}
                ellipsis={true}
                bordered={true}
                dataSource={dataSource}
                columns={columns}
            />
        );
    }

    copyFrameClickHandler(frame) {
        const newFrameID = generateFrameID(this.props.frames);
        let tempFrame = {...frame};
        tempFrame.id = newFrameID;
        this.props.copyFrame(tempFrame);
        this.props.setActiveFrame(newFrameID);
    }

    moveButtonClickHandler(direction, frame) {
        const iterator = direction === "UP" ? 1 : -1;
        const nextFrame = this.props.frames.filter((i1) => i1.id == (frame.id + iterator))[0];
        if (nextFrame) {
            const nextFrameID = nextFrame.id;
            const currentFrameID = frame.id;
            frame.id = nextFrameID;
            nextFrame.id = currentFrameID;
            this.props.replaceFrame(nextFrameID, frame);
            this.props.replaceFrame(currentFrameID, nextFrame);
        }
    }

    frameDurationChangeHandler(frameID, newDuration) {
        this.props.changeFrameDuration(frameID, parseFloat(newDuration));
    }

    renderListItem(item, index) {
        const title = this.generateFrameTitle(item.id);
        const CoordsContainer = (
            <Popover destroyTooltipOnHide={true} placement="left" content={this.renderMotorTextInputForm(item)} trigger="click">
                <Tooltip title="Manuel Giriş">
                    <Icon className="frame-viewer-coords-icon" type="radius-setting"/>
                </Tooltip>
            </Popover>
        );
        const CopyContainer = (
            <Tooltip title="Kopyasını Oluştur">
                <Icon className="frame-viewer-copy-icon" type="copy" onClick={() => this.copyFrameClickHandler(item)}/>
            </Tooltip>
        );
        const MoveToUpContainer = (
            <Tooltip title="Yukarı Taşı">
                <Icon className="frame-viewer-move-icon" type="up" onClick={() => this.moveButtonClickHandler("UP", item)}/>
            </Tooltip>
        );
        const MoveToDownContainer = (
            <Tooltip title="Aşağı Taşı">
                <Icon className="frame-viewer-move-icon" type="down" onClick={() => this.moveButtonClickHandler("DOWN", item)}/>
            </Tooltip>
        );
        const SelectContainer = (
            <Tooltip title="Katmanı Düzenle">
                <Icon className="frame-viewer-select-icon" type="select"
                    onClick={(e) => this.selectButtonClickHandler(item.id)}/>
            </Tooltip>
        );
        const DeleteContainer = (
            <Popconfirm
                title={`${title}ı silmek istediğinize emin misiniz?`}
                placement={"right"}
                onConfirm={(e) => this.deleteButtonClickhandler(item.id)}
                okText="Evet"
                cancelText="Hayır"
            >
                <Tooltip title="Katmanı Sil">
                    <Icon className="frame-viewer-delete-icon" type="delete"/>
                </Tooltip>
            </Popconfirm>
        );
        let actions = [];
        const reverseIndex = (this.props.frames.length - 1) - index;
        if (reverseIndex > 0) {
            if (reverseIndex != this.props.frames.length -1) {
                actions.push(MoveToUpContainer);
            }
            actions.push(MoveToDownContainer);
        }
        actions = [...actions, ...[CoordsContainer, CopyContainer, SelectContainer, DeleteContainer]];
        return (
            <Item className={item.id === this.props.activeFrame ? "selected-frame" : null} actions={actions} style={{padding: '12px'}}>
                <Meta title={title}/>
                <div>
                    {item.id != 1 ?
                        <span>
                            <Input
                                type="number"
                                value={item.duration}
                                suffix="sn"
                                style={{maxWidth: '100px'}}
                                onChange={(e) => this.frameDurationChangeHandler(item.id, e.target.value)}
                            />
                        </span> : null}
                </div>
            </Item>
        );
    }

    newFrameClickHandler(frameID) {
        const {newDuration} = this.state;
        const {createNewFrame, motors} = this.props;
        const motorPairs = this.createMotorPairs(motors);
        let coords = {};
        motorPairs.forEach((i1) => {
            if (i1.length === 1) {
                coords[i1[0]] = {
                    x: motors[i1[0]].position
                }
            }
        });
        if (Object.keys(coords).length == 0) {
            coords = null;
        }
        return (e) => {
            createNewFrame(frameID, newDuration, coords);
        }
    }

    hideLinkPop() {
        this.setState({linkPopVisible: false});
    }

    handleLinkPopVisibleChange() {
        this.setState({linkPopVisible: !this.state.linkPopVisible});
    }

    generateCommanderLink() {
        const office_id = this.props.office_id || "-";
        const base = `https://app-api.levitate.com.tr/motor-commands`;
        return `${base}/${office_id}/1/0`;
    }

    render() {
        const {frames} = this.props;
        const newFrameID = generateFrameID(frames);
        if (frames && frames.length === 0) {
            this.newFrameClickHandler(newFrameID)();
        }
        return (
            <div>
                <Item
                    style={{borderBottom: '1px solid #e8e8e8', padding: '24px 12px'}}>
                    <Button onClick={this.newFrameClickHandler(newFrameID)} block={true}>Yeni Katman Ekle</Button>
                    <IconButton>
                        <Popover
                            content={
                                <div>
                                    <CommandLink><a href={this.generateCommanderLink()}
                                                    target="_blank">{this.generateCommanderLink()}</a></CommandLink>
                                    |
                                    <a style={{marginLeft: '4px'}} onClick={this.hideLinkPop}>X</a>
                                </div>
                            }
                            trigger="click"
                            visible={this.state.linkPopVisible}
                            onVisibleChange={this.handleLinkPopVisibleChange}
                        >
                            <Button type="primary">
                                <Icon type="link"/>
                            </Button>
                        </Popover>
                    </IconButton>
                </Item>
                <List
                    style={{height: 'calc(100vh - 300px)', overflow: 'scroll'}}
                    itemLayout="horizontal"
                    dataSource={frames.sort((i1, i2) => i2.id > i1.id ? 1 : -1)}
                    renderItem={this.renderListItem}
                />
            </div>
        )
    }
}

export default connect((state) => ({
    frames: state && state.designer && state.designer.frames || null,
    motors: state && state.designer && state.designer.motors || null,
    activeFrame: state && state.designer && state.designer.activeFrame || null,
    motorCount: state && state.designer && state.designer.motorCount || 4,
}), {createNewFrame, deleteFrame, setActiveFrame, replaceFrame, copyFrame, changeFrameDuration})(FrameViewer);
