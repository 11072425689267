import React from 'react';
import { connect } from 'react-redux';
import {Form, Select} from "antd";
import CommanderInput from './CommanderInput';
import { setMotorPairs } from "../../../reduxUtils/actions";
const motorNames = ['first', 'second', 'third', 'fourth'];
const nameToNumberMap = {
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
}
class MotorConfigurationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
        this.handlePairSelection = this.handlePairSelection.bind(this);
        this.showPairSelection = this.showPairSelection.bind(this);
    }

    handlePairSelection(value) {
        const { setMotorPairs } = this.props;
        setMotorPairs(value);
    }

    showPairSelection(motorCount) {
        switch(motorCount) {
            case 1:
                return (
                    <Select defaultValue="" style={{ width: '100%' }} onChange={this.handlePairSelection}>
                        <Select.Option value="">Tek</Select.Option>
                    </Select>
                );
                break;
            case 2:
                return (
                    <Select defaultValue="" style={{ width: '100%' }} onChange={this.handlePairSelection}>
                        <Select.Option value="">Tek - Tek</Select.Option>
                        <Select.Option value="first:second">Çift</Select.Option>
                    </Select>
                );
                break;
            case 3:
                return (
                    <Select defaultValue="" style={{ width: '100%' }} onChange={this.handlePairSelection}>
                        <Select.Option value="">Tek - Tek - Tek</Select.Option>
                        <Select.Option value="first:second">Çift - Tek</Select.Option>
                        <Select.Option value="second:third">Tek - Çift</Select.Option>
                    </Select>
                );
                break;
            default:
                return (
                    <Select defaultValue="" style={{ width: '100%' }} onChange={this.handlePairSelection}>
                        <Select.Option value="">Tek - Tek - Tek - Tek</Select.Option>
                        <Select.Option value="first:second">Çift - Tek - Tek</Select.Option>
                        <Select.Option value="second:third">Tek - Çift - Tek</Select.Option>
                        <Select.Option value="third:fourth">Tek - Tek - Çift</Select.Option>
                        <Select.Option value="first:second,third:fourth">Çift - Çift</Select.Option>
                    </Select>
                );
                break;
        }
    }

    render() {
        const motorInputs = motorNames.slice(0, this.props.motorCount);
        return(
            <Form layout="vertical">
                {motorInputs.map( (i1, index) => {
                    return (
                        <Form.Item key={`positionInput-${index}`} label={`${nameToNumberMap[i1]}. Makaranın Pozisyonu`} style={{width: '100%'}}>
                            <CommanderInput mainStore="motors" storePath={`${i1}.position`} motorName={`${i1}`}  style={{ width: '100%' }} addonAfter="cm"/>
                        </Form.Item>
                    )
                })}
                <Form.Item label="Makara eşleri" style={{ width: '100%' }}>
                    {this.showPairSelection(this.props.motorCount)}
                </Form.Item>
            </Form>
        )
    }
}
export default connect( (state) => {
    return {
        motorCount: state && state.designer && state.designer.motorCount || 4
    }
}, { setMotorPairs })(MotorConfigurationForm);

/* Tek -> 1111
-----
Tek - Tek -> 1111
Çift -> 0011
-----
Tek - Tek - Tek -> 1111
Çift- Tek -> 0011
Tek - Çift -> 1001
 */
