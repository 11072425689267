import {designerAcitonTypes} from '../actions';

/*const defaultState = {
    showcaseDimensions: {
        width: 800,
        height: 600,
    },
    motors: {
        first: {
            position: 0,
        },
        second: {
            position: 190,
        },
        third: {
            position: 210,
        },
        fourth: {
            position: 600,
        }
    },
    frames: [],
    activeFrame: null,
    motorOrders: ['first', 'second', 'third', 'fourth'],
};*/
const defaultState = {
    initialized: false,
    showcaseDimensions: {
        width: 0,
        height: 0,
    },
    motors: {
        first: {
            position: 0,
        },
        second: {
            position: 0,
        },
        third: {
            position: 0,
        },
        fourth: {
            position: 0,
        }
    },
    frames: [],
    activeFrame: null,
    motorOrders: ['first', 'second', 'third', 'fourth'],
    motorCount: 4
};

export function designerReducer(state = defaultState, {type, data}) {
    let newState = {...state};
    switch (type) {
        case designerAcitonTypes.ADD_MOTOR:
            break;
        case designerAcitonTypes.REMOVE_MOTOR:
            break;
        case designerAcitonTypes.CHANGE_MOTOR_POSITION:
            if (!newState.motors) {
                newState.motors = {};
            }
            if (!newState.motors[data.motorName]) {
                newState.motors[data.motorName] = {};
            }
            newState.motors[data.motorName].position = parseFloat(data.position);
            return {...newState};
            break;
        case designerAcitonTypes.SET_MOTOR_PAIRS:
            newState.motors = data.motors;
            newState.pairString = data.pairString;
            return {...newState};
            break;
        case designerAcitonTypes.CHANGE_SHOWCASE_DIMENSIONS:
            newState.showcaseDimensions = {
                width: data.width || (state.showcaseDimensions && state.showcaseDimensions.width),
                height: data.height || (state.showcaseDimensions && state.showcaseDimensions.height),
            };
            return {...newState};
            break;
        case designerAcitonTypes.CHANGE_MOTOR_COUNT:
            newState.motorCount = data.motorCount;
            delete newState.motors.second.disabled;
            delete newState.motors.third.disabled;
            delete newState.motors.fourth.disabled;
            if (data.motorCount == 1) {
                newState.motors.second.disabled = true;
                newState.motors.third.disabled = true;
                newState.motors.fourth.disabled = true;
            }
            if (data.motorCount == 2) {
                newState.motors.third.disabled = true;
                newState.motors.fourth.disabled = true;
            }
            if (data.motorCount == 3) {
                newState.motors.fourth.disabled = true;
            }
            return {...newState};
            break;
        case designerAcitonTypes.CREATE_NEW_FRAME:
            const newFrames = [...state.frames, data];
            newState.frames = newFrames;
            newState.activeFrame = data.id;
            return {...newState};
            break;
        case designerAcitonTypes.SET_MOTOR_POSITON_IN_FRAME:
            const { frameID, motorName, coords } = data;
            let frameWithCoords = newState.frames.filter( (i1) => i1.id === frameID)[0];
            newState.frames = newState.frames.filter( (i1) => i1.id !== frameID); // remove old frame
            frameWithCoords.coords = {...frameWithCoords.coords};
            frameWithCoords.coords[motorName] = coords;
            let motorPair = newState.motors[motorName].pair;
            if (motorPair) {
                frameWithCoords.coords[motorPair] = coords;
            }
            newState.frames.push(frameWithCoords);
            newState.frames.sort((i1, i2) => i1.id > i2.id ? 1 : -1);
            return {...newState};
            break;
        case designerAcitonTypes.INITIALIZE_DESIGNER:
            const { frames = [], motors, motorOrders, pairString, showcaseDimensions } = data;
            if (frames && frames.length > 0) {
                newState.frames = frames.sort( (i1,i2) => i2.id > i1.id ? 1 : -1 );
                newState.activeFrame = frames[0].id;
            }
            newState.motors = motors;
            if (motorOrders) {
                newState.motorOrders = motorOrders;
            }
            if (pairString) {
                newState.pairString = pairString;
            }
            newState.showcaseDimensions = showcaseDimensions || newState.showcaseDimensions;
            newState.initialized = true;
            return {...newState};
            break;
        case designerAcitonTypes.SET_ACTIVE_FRAME:
            newState.activeFrame = data.frameID;
            return {...newState};
            break;
        case designerAcitonTypes.DELETE_FRAME:
            newState.frames = newState.frames.filter(i1 => i1.id != data.frameID);
            if (newState.activeFrame == data.frameID) {
                let tempFrames = [...newState.frames];
                let lastFrame = tempFrames.sort((i1,i2) =>i2.id > i1.id ? 1 : -1)[0];
                if (lastFrame && typeof lastFrame.id === 'number') {
                    newState.activeFrame = lastFrame.id;
                }
            }
            return {...newState};
            break;
        case designerAcitonTypes.REPLACE_FRAME:
            newState.frames = newState.frames.map(i1 => {
                if (i1.id === data.frameID) {
                    i1.coords = data.newFrame.coords || i1.coords;
                    i1.duration = data.newFrame.duration || i1.duration;
                }
                return i1;
            });
            return {...newState};
        case designerAcitonTypes.COPY_FRAME:
            newState.frames.push(data.frameData);
            return {...newState};
        case designerAcitonTypes.CHANGE_FRAME_DURATION:
            newState.frames = newState.frames.map(i1 => {
               if (i1.id === data.frameID) {
                   i1.duration = data.newDuration
               }
               return i1;
            });
            return {...newState};
        default:
            return state;
    }
}
