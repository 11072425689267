import React, { useState, useEffect } from 'react';
import {Input} from "antd";
import { connect } from 'react-redux';
import {changeMotorPosition, changeShowcaseDimensions, changeMotorCount} from '../../../reduxUtils/actions'

function CommanderInput(props) {
    const { mainStore, storePath, motorName, defaultValue, ...rest } = props;
    const { changeMotorPosition, changeShowcaseDimensions, changeMotorCount, ...inputRest } = rest;
    const parsedDefaulValue = parseFloat(defaultValue);
    const [value, setValue] = useState(isNaN(parsedDefaulValue) ? 0 : parsedDefaulValue);
    useEffect( () => {
        let parsedDefaultVal = parseFloat(props.defaultValue);
        setValue(isNaN(parsedDefaultVal) ? 0 : parsedDefaultVal)
    }, [ parsedDefaulValue ]);
    let onBlurHander = function(e) {
        if (mainStore === 'motors') {
            changeMotorPosition(motorName, value);
        } else if (mainStore === 'showcaseDimensions') {
            changeShowcaseDimensions({ [storePath]: value });
        } else if (mainStore === 'motorCount') {
            changeMotorCount(parseInt(value));
        }
    };

    let onChangeHandler = function (e) {
        setValue(e.target.value)
    }

    return(
        <Input onBlur={onBlurHander} onChange={onChangeHandler} value={value < 0 ? '' : value} {...inputRest} />
    )
}

export default connect((state, ownProps) => {
    if (ownProps.storePath) {
        return {
            defaultValue: Object.byString(state, ['designer', ownProps.mainStore, ownProps.storePath].join('.'))
        }
    }
    return {
        defaultValue: Object.byString(state, ['designer', ownProps.mainStore].join('.'))
    }
}, { changeMotorPosition, changeShowcaseDimensions, changeMotorCount } )(CommanderInput);
